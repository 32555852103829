.marker {
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  height: 100%;
  position: relative;
}

/* div containing the marker parent */
.clearMarkerContainer {
  background-color: rgba(57, 57, 216, 0);
  display: flex;
  justify-content: center;
}
